const Theme = {
  colors: {
    text: "#333",
    headerBackground: "#4a9ad5ff",
    headerText: "white",
    paperBackgroundColor: "white",
    paperHoverBackgroundColor: "#f9f9f9",
    paperHeadingColor: "#2976afff",
    paperHoverHeadingColor: "#4a9ad5ff",
    paperBorderColor: "transparent",
    paperDescriptionColor: "rgb(86, 88, 103)",
    collectionBackgroundColor: "#EBEEF1",
    collectionHeading: "#3a3c4c",
    collectionDescription: "#565867",
    breadcrumbLinkTextColor: "#4f5e6b",
    breadcrumbHoverLinkTextColor: "#242a30",
    breadcrumbTextColor: "#8f919d",
    articleTextColor: "#565867",
    articleDescriptionColor: "#8F919D",
    background: "#fff",
    greyBackground: "#f3f5f7",
    primary: "#639",
    secondary: "#ff6347",
    muted: "#8f919d",
    searchTextColor: "#3a3c4c",
    searchTextFocusColor: "black",
    searchTextPlaceholderColor: "#3a3c4c",
    searchTextFocusPlaceholderColor: "black",
    comboboxColor: "black",
    iconColor: "#828A97",
    logoColor: "white",
    footerLogo: "#4a9ad5ff",
    footerTextColor: "#828A97",
    footerTextHoverColor: "#242a30",
    buttonOutlinedBorder: "#4a9ad5ff",
    buttonOutlinedText: "#4a9ad5ff",
    buttonOutlinedBackground: "white",
    buttonOutlinedHover: "#808080",
    buttonContainedBackground: "#4a9ad5ff",
    buttonContainedText: "white",
    buttonContainedHover: "#808080",
  },
  radii: [0, 2, 4, 8],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    monospace: "Roboto Mono, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    small: 1.2,
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  /* Styles for MDX */
  styles: {
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      marginBottom: 0,
    },
    a: {
      color: "primary",
      ":hover, :focus": {
        color: "secondary",
      },
    },
    blockquote: {
      marginLeft: "1.75rem",
    },
  },
}

export default Theme
